import { useState, useEffect } from 'react'
import styled from 'styled-components'
import useTheme from 'hooks/useTheme'
import { useTranslation } from '@pancakeswap/localization'
import dynamic from 'next/dynamic'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { gumballBaseUrl } from 'views/Gumball/constants'
import { LocationLinkInfo, LocationModal } from '../../components/LocationModal'
import useLanguage from "blog/hooks/useLanguage";
import { useRouter } from 'next/router'
import replaceBrowserHistory from '@pancakeswap/utils/replaceBrowserHistory'
import LocationIcon from "../../components/Svg/LocationIcon";

// 必须使用动态导入，否则会导致服务端渲染失败
const ImageHotspots = dynamic(() => import('react-image-hotspots'), { ssr: false })

/* export const LinkBox = styled.div`
  padding: 8px;
  background: ${({ theme }) => theme.colors.gradientInverseBubblegum};
  border-radius: 8px;
  font-size: 15px;
  color: ${({ theme }) => theme.colors.primary};
` */

export const LinkBox = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  .img{
    width: 32px;
    height: 45px;
  }
`

export const LinkLabel = styled.div<{ $bg: string}>`
  padding: 5px 14px;
  border-radius: 12px;
  height: 24px;
  font-size: 14px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 7px;
  ${({ $bg }) => `background-color: ${$bg};`}
`


const Home: React.FC<React.PropsWithChildren> = () => {
  const { theme } = useTheme()
  const { t, currentLanguage } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const [linkInfo, setLinkInfo] = useState<LocationLinkInfo>({
    title: '',
    href: '',
    desc: '',
    target: '_self',
    time: '',
    buttonTitle: '',
  })
  const [hoverTimer, setHoverTimer] = useState<NodeJS.Timeout|null>(null)

  const { query } = useRouter()
  useEffect(() => {
    window.scroll((1877 - window.innerWidth) / 2,  0) // (1055 - window.innerHeight)/2
    if(query?.modal){
      setIsOpen(true)
      setLinkInfo({
        title: '',
        titleImage: `/images/home/miracle-${currentLanguage.code}.png`,
        desc: t('miracle-desc'),
        time: t('May'),
      })
      replaceBrowserHistory('modal')
    }
  }, [query])

  return (
    <>
      <style jsx global>
        {`
          #home-1 {
            background: ${theme.colors.gradientBubblegum};
          }
          .spot-image {
            width: 32px;
            height: 39px;
          }
        `}
      </style>
      {/* todo 包裹IMageHotspots导致无法往下拖动
        <TransformWrapper centerOnInit>
        <TransformComponent wrapperStyle={{ maxWidth: '100%',maxHeight: '100%' }}> */}
      <div
        id="home-1"
        style={{
          height: '1055px',
          width: '1877px',
        }}
      >
        {/*onMouseLeave={() => {
        clearTimeout(hoverTimer)
      }}
        onMouseEnter={() => {
        if(hoverTimer) clearTimeout(hoverTimer)
        setHoverTimer(
          setTimeout(() => {
            setIsOpen(true)
            setLinkInfo({
              title: '',
              titleImage: '/images/home/marketplace.png',
              desc: t('marketplace-dessc)',
              href: '/swap',
              target: '_self',
            })
          }, 500)
        )
      }}*/}
        <ImageHotspots
          src="/images/land0414.jpg"
          alt="Petaverse Landscape"
          hideFullscreenControl
          hideminimap
          background={theme.colors.gradientBubblegum}
          hideZoomControls
          hotspots={[
            {
              x: 49.9,
              y: 81.1,
              style: { background: 'transparent', pointerEvents: 'auto' }, // 不加上pointerEvents:'auto'，内部的NextLink无法点击
              content: (
                <LinkBox
                  onClick={() => {
                    setIsOpen(true)
                    setLinkInfo({
                      title: '',
                      titleImage: `/images/home/marketplace-${currentLanguage.code}.png`,
                      desc: t('marketplace-desc'),
                      href: '/swap',
                      target: '_self',
                    })
                  }}
                >
                  <img src="/images/home/location7.gif" alt="Marketplace" />
                  <LinkLabel $bg="#9d75e2">{t('Marketplace')}</LinkLabel>
                </LinkBox>
              ),
            },
            {
              x: 30.6,
              y: 63,
              style: { background: 'transparent', pointerEvents: 'auto' },
              content: (
                <LinkBox
                  onClick={() => {
                    setIsOpen(true)
                    setLinkInfo({
                      title: '',
                      titleImage: `/images/home/bank-${currentLanguage.code}.png`,
                      desc: t('bank-desc'),
                      href: '/pools',
                    })
                  }}
                >
                  <img src="/images/home/location1.gif" alt="Bank" />
                  <LinkLabel $bg="#00bbeb">{t('Pools')}</LinkLabel>
                </LinkBox>
              ),
            },
            {
              x: 41.8,
              y: 22,
              style: { background: 'transparent', pointerEvents: 'auto' },
              content: (
                <LinkBox
                  onClick={() => {
                    setIsOpen(true)
                    setLinkInfo({
                      title: '',
                      titleImage: `/images/home/metaversegate-${currentLanguage.code}.png`,
                      desc: t('metaverse-desc'),
                      href: '/meta',
                      buttonTitle: t('Preview')+' >',
                      // href: process.env.NEXT_PUBLIC_PETA_METAVERSE_DOMAIN,
                      // target: '_blank',
                    })
                  }}
                >
                  <img src="/images/home/location2.gif" alt="METAVERSE GATE" />
                  <LinkLabel $bg="#fa8907">{t('METAVERSE GATE')}</LinkLabel>
                </LinkBox>
              ),
            },
            {
              x: 57.2,
              y: 47.2,
              style: { background: 'transparent', pointerEvents: 'auto' },
              content: (
                <LinkBox
                  onClick={() => {
                    setIsOpen(true)
                    setLinkInfo({
                      title: '',
                      titleImage: `/images/home/gachapark1-${currentLanguage.code}.png`,
                      desc: t('gacha-desc'),
                      href: `${gumballBaseUrl}#/gachaMachine`,
                      target: '_blank',
                    })
                  }}
                >
                  <img src="/images/home/location3.gif" alt="Gumball" />
                  <LinkLabel $bg="#496ab3">{t('Gumball')}</LinkLabel>
                </LinkBox>
              ),
            },
            {
              x: 64.4,
              y: 59.2,
              style: { background: 'transparent', pointerEvents: 'auto' },
              content: (
                <LinkBox
                  onClick={() => {
                    setIsOpen(true)
                    setLinkInfo({
                      title: '',
                      titleImage: `/images/home/miracle-${currentLanguage.code}.png`,
                      desc: t('miracle-desc'),
                      // time: t('May'),
                      href: '/miracle',
                    })
                  }}
                >
                  <img src="/images/home/location4.gif" alt="Miracle" />
                  <LinkLabel $bg="#8199af">{t('Farms')}</LinkLabel>
                </LinkBox>
              ),
            },
            {
              x: 43,
              y: 54,
              style: { background: 'transparent', pointerEvents: 'auto' },
              content: (
                <LinkBox
                  onClick={() => {
                    setIsOpen(true)
                    setLinkInfo({
                      title: '',
                      titleImage: `/images/home/academy-${currentLanguage.code}.png`,
                      desc: t('academy-desc'),
                      href: '/peta',
                    })
                  }}
                >
                  <img src="/images/home/location5.gif" alt="Academy" />
                  <LinkLabel $bg="#be6545">{t('Peta Academy')}</LinkLabel>
                </LinkBox>
              ),
            },
            {
              x: 20,
              y: 45.8,
              style: { background: 'transparent', pointerEvents: 'auto' },
              content: (
                <LinkBox
                  onClick={() => {
                    setIsOpen(true)
                    setLinkInfo({
                      title: '',
                      titleImage: `/images/home/councilhall-${currentLanguage.code}.png`,
                      desc: t('councilhall-desc'),
                      // time: t('August'),
                      href: '/peta/roadmap',
                    })
                  }}
                >
                  <img src="/images/home/location6.gif" alt="Council Hall" />
                  <LinkLabel $bg="#388e2b">{t('Council Hall')}</LinkLabel>
                </LinkBox>
              ),
            },
          ]}
        />
      </div>
      {/*  </TransformComponent> */}
      {/* </TransformWrapper> */}
      <LocationModal
        isOpen={isOpen}
        info={linkInfo}
        onDismiss={() => {
          setIsOpen(false)
        }}
      />
    </>
  )
}

export default Home
