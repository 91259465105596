import { Box, Button, Flex, Message, MessageText, Modal, ModalV2, Text } from "@pancakeswap/uikit";
import { useTranslation } from '@pancakeswap/localization'
import NextLink from "@pancakeswap/uikit/src/components/NextLink/NextLink";
import React from "react";
import useTheme from 'hooks/useTheme'

export interface LocationLinkInfo {
  href?: string
  target?: string
  time?: string
  title: React.ReactNode,
  titleImage?: string,
  desc: string|React.ReactNode,
  buttonTitle?: string,
}

export const LocationModal = ({ info, isOpen,onDismiss }: { info: LocationLinkInfo; isOpen: boolean,onDismiss?:()=>void }) => {
  const { t } = useTranslation()
  const { theme } = useTheme()

  return (
    <ModalV2 isOpen={isOpen} onDismiss={onDismiss} closeOnOverlayClick>
      <Modal title={info.title} onDismiss={onDismiss} headerBackground="modal.background">
        <Flex flexDirection="column" alignSelf="center" alignItems="center" width="360px">
          {info.titleImage && <img src={info.titleImage} style={{height:'30px'}} alt="modal-title" />}
          <p style={{textAlign:'center',color:theme.colors.text,lineHeight:'24px',fontSize:'16px',marginTop:info.titleImage?'30px':'0',padding:'0rem 1rem'}}>{info.desc}</p>
          {info.href?(<NextLink to={info.href} target={info.target} style={{width:"60%"}}>
            <Button
              onClick={onDismiss}
              variant="secondary"
              mt="24px"
              id="link-button"
              width="100%"
            >
              {info.buttonTitle?info.buttonTitle:t('Enter')+' >'}
            </Button>
          </NextLink>):( info.time &&
            <p style={{marginTop:"24px"}}>
              <span style={{color:theme.colors.primary,fontSize:'16px',marginRight:"10px"}}>{t('Opening in')}:</span><span style={{color:theme.colors.text99,fontSize:'20px',fontWeight:'bold'}}>{info.time}</span>
            </p>
          )}
        </Flex>
      </Modal>
    </ModalV2>
  )
}
